import React from 'react';
import { Block, Layout, PillButton } from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO } from '../../../components/_index';

const ContestRules = () => {
  return (
    <PageLayout>
      <SEO
        title="Open Source Money Contest Rules"
        description="Contest Rules for the Open Source Money Contest"
      />
      <Layout dark background="blue" header="Contest Rules" />

      <Layout>
        <Block type="full">
          <div className="blog">
            <PillButton type="link" to="/contests/sky-color">
              &#10554; back to contest
            </PillButton>
            <br />
            <br />
            <div className="blog-article">
              <div className="article-body">
                <p>This page is intentionally blank</p>
              </div>
            </div>
          </div>
        </Block>
      </Layout>
    </PageLayout>
  );
};

export default ContestRules;
